.form {
  position: relative;
  background-color:#0e0f1f;
  /* background: #202153; */
  /* border: 3px solid #FFFFFF; */
  border-radius: 86px;
  max-width: 890px;
  display: flex;
  flex-direction: column;
  padding: 40px 60px;
  align-items: center;
  justify-content: center;
  max-width: 55%;
}

.form__block-input {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  color: #f1f1f1;
  min-height: 45px;
  width: 100%;
  margin: 0;
}

.form__input-error {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  align-items: center;
  letter-spacing: 0.15em;
  line-height: 20px;
  color: #cc0202;
  margin: 0 0 0 10px;
}

.form__close {
  background-image: url(../../images/Close.svg);
  width: 40px;
  height: 40px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 30px;
  right: 40px;
  border: none;
  background-color: inherit;
  cursor: pointer;
  opacity: 0;
}

.form__close_visible {
  opacity: 1;
}

.form__title {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 25px;
  color: #f1f1f1;
  letter-spacing: 0.15em;
  line-height: 42px;
  margin: 0 auto;
  text-align: center;
  width: 85%;
}

.form__inputs {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.form__two-inputs {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}

.form__input {
  border: 1px solid rgba(255, 255, 255, 0.16);
  border-radius: 240px;
  background-color: transparent;
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 15px;
  align-items: center;
  color: #f1f1f1;
  letter-spacing: 0.15em;
  line-height: 20px;
  margin-top: 30px;
  padding-left: 10px;
  min-height: 45px;
  width: 100%;
  box-sizing: border-box;
}

.form__input_type_phone {
  margin-right: 20px;
}

.form__input_type_phone::after:focus-within {
  initial-value: "+7";
}


.form__input_type_description {
  border-radius: 20px;
  resize: none;
  height: 70px;
  line-height: 30px;
}

.form__btn {
  background: #5256BE;
  border-radius: 24px;
  border: 0;
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 24px;
  align-items: center;
  color: #f1f1f1;
  letter-spacing: 0.15em;
  line-height: 32px;
  width: 350px;
  height: 50px;
  cursor: pointer;
  align-self: center;
  margin: 30px 0;
}

.form__btn:disabled {
  background: #8f8f8f;
  opacity: .5;
}

.form__btn:active {
  background: #4a4fce;
}

.form__contacts {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
}

.form__line {
  border: 0.5px solid #FFFFFF;
  height: 0;
  max-width: 350px;
  width: 45%;
  align-self: center;
}

.form__or {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  align-items: center;
  color: #f1f1f1;
  letter-spacing: 0.15em;
  line-height: 32px;
  margin: 0 20px 0;
}

.form__text {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  align-items: center;
  color: #f1f1f1;
  letter-spacing: 0.15em;
  line-height: 32px;
}

.form__link {
  display: flex;
  flex-direction: row;
}

.form__icon {
  width: 60px;
  height: 60px;
  margin-left: 20px;
}

.form__container {
  display: none;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.form__container_visible {
  display: flex;
}

.form__answer-good {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  align-items: center;
  color: #f1f1f1;
  letter-spacing: 0.15em;
  line-height: 32px;
  display: none;
  text-align: center;
}

.form__answer-good_visible {
  display: block;
}

@media screen and (min-width: 1441px) {
  .form {
    padding: 50px 100px 50px;
  }

  .form__close {
    top: 40px;
    right: 50px;
  }

  .form__title {
    font-size: 25px;
    line-height: 40px;
  }
}

@media screen and (max-width: 1439px) {
  .form {
    padding: 40px 60px;
  }

  .form__title {
    font-size: 25px;
    line-height: 32px;
  }
}

@media screen and (max-width: 1100px) {
  .form {
    padding: 40px 60px;
    max-width: 70%;
  }

  .form__close {
    width: 30px;
    height: 30px;
  }

  .form__title {
    font-size: 20px;
  }

  .form__input {
    font-size: 15px;
    min-height: 40px;
  }
}

@media screen and (max-width: 950px) {
  .form {
    border-radius: 60px;
  }

  .form__close {
    top: 30px;
    right: 30px;
  }

  .form__input {
    font-size: 15px;
    min-height: 40px;
    margin-bottom: 20px;
  }

  .form__input_type_description {
    margin: 0;
  }

  .form__btn {
    font-size: 15px;
    width: 100%;
    height: 40px;
    margin-bottom: 20px;
  }

  .form__link {
    align-items: center;
  }

  .form__contacts {
    margin-bottom: 15px;
  }

  .form__or {
    font-size: 15px;
    margin: 0 10px 0;
  }

  .form__text {
    font-size: 15px;
  }

  .form__icon {
    width: 50px;
    height: 50px;
  }
}

@media screen and (max-width: 550px) {
  .form {
    padding: 30px;
    border-radius: 40px;
    max-width: 70%;
  }

  .form__two-inputs {
    gap: 0;
  }

  .form__close {
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
  }

  .form__title {
    font-size: 17px;
    line-height: 25px;
    margin: 0 auto 25px;
  }

  .form__input {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 10px;
  }

  .form__two-inputs {
    flex-direction: column;
  }

  .form__contacts {
    margin-bottom: 10px;
  }

  .form__or {
    font-size: 10px;
    margin: 0 10px 0;
  }

  .form__text {
    font-size: 10px;
  }

  .form__icon {
    width: 35px;
    height: 35px;
    margin: 0 0 0 7px;
  }

  .form__btn {
    margin: 10px 0;
  }

  .form__answer-good {
    font-size: 15px;
  }
}

@media screen and (max-width: 380px) {
  .form {
    padding: 20px;
    box-sizing: content-box;
  }

  .form__title {
    font-size: 13px;
    line-height: 22px;
  }
}