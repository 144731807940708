@import url(../src/vendor/normalize.css);
@import url(../src/vendor/font/font.css);

*,
html * /* override x.xhtml.ru style */
{
  scrollbar-width: thin;
  scrollbar-color: #656565 #4845e8;
}

*::-webkit-scrollbar,
html *::-webkit-scrollbar {
  width: 10px;
  background-color: #656565;
}

*::-webkit-scrollbar-track,
html *::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  background-color: #656565;
}

*::-webkit-scrollbar-thumb,
html *::-webkit-scrollbar-thumb {
  background-image: linear-gradient(180deg, #656565 0%, #4845e8 99%);
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
  overflow-y: scroll;
}

.page {
	color: rgb(255, 255, 255);
	font-family: "Manrope", "Arial", sans-serif;
	min-width: 320px;
  width: 100%;
  margin: 0 auto;
  background: linear-gradient(180deg, #656565 0%, #0B0D30 100%);
}
