.portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
  position: relative;
}

.portfolio__title {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  align-items: center;
  color: #f1f1f1;
  margin: 50px 0 0;
  padding-right: 40px;
  box-sizing: border-box;
  display: inline-block;
  align-self: flex-start;
  letter-spacing: 0.15em;
  line-height: 32px;
  display: flex;
  justify-content: flex-end;
  background-color: #4845e899;
  border-radius: 60px;
  height: 100px;
  width: 600px;
  position: absolute;
  left: -200px;
}

.portfolio__work {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 16px;
  margin: 200px 0 30px;
}

.portfolio__video {
  width: 100%;
  min-height: 250px;
  min-width: 350px;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.5s;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
  padding: 0 15px;
}

.portfolio__video:hover {
  transform: scale(110%);
}

.portfolio-video__title {
  /* margin-top: 100px; */
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  color: #f1f1f1;
  letter-spacing: 0.15em;
  line-height: 32px;
}

/* .portfolio__arrow {
  top: calc(100vh - 100px);
  background-repeat: no-repeat;
  height: 40px;
  width: 100px;
  animation: go-arrow 1.5s 4 alternate-reverse ease-in 1s;
  background-image: url(../../images/arrow.svg);
  background-size: contain;
  cursor: pointer;
  margin: 0 auto 30px;
} */

.portfolio__arrow {
    border: 3px solid #4543E8;
    border-radius: 79px;
    width: 200px;
    height: 80px;
    background-color: inherit;
    color: #FFFFFF;
    font-style: normal;
    font-weight: 200;
    font-size: 20px;
    align-items: center;
    text-align: center;
    cursor: pointer;
    padding: 0;
    margin: 0 auto 30px;
    transition: background-color 0.6s;
    letter-spacing: 0.15em;
    line-height: 32px;
  }

  .portfolio__arrow:hover {
    background-color: rgb(82, 86, 190, 0.6);
  }


  @media screen and (min-width: 1700px) {
    .portfolio {
      padding: 0 50px 50px;
    }

    .portfolio__title {
      width: 600px;
      height: 150px;
      font-size: 45px;
      margin: 100px 0;
    }

    .portfolio__work {
      width: 100%;
      max-width: 2200px;
      gap: 30px;
      margin: 350px 0 30px;
    }

    .portfolio__video {
      width: 100%;
      min-height: 400px;
      min-width: 500px;
      border-radius: 10px;
      cursor: pointer;
      transition: transform 0.5s;
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.4);
      border: none;
      padding: 0 15px;
    }
  }

  @media screen and (max-width: 1200px) {
    .portfolio__video {
      min-height: 200px;
      min-width: 300px;
    }
  }

  @media screen and (max-width: 1025px) {
    .portfolio__title {
      width: 450px;
      height: 70px;
      font-size: 20px;
      margin: 30px 0;
    }

    .portfolio__work {
      margin: 130px 0 30px;
    }

    .portfolio {
      height: fit-content;
    }

    .portfolio-video__title {
      font-size: 15px;
      line-height: 20px;
    }

    .portfolio__work {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(3, 1fr);
      gap: 10px;
    }
  }

  @media screen and (max-width: 770px) {
    .portfolio__video {
      min-height: 150px;
      min-width: 200px;
    }
  }

  @media screen and (max-width: 470px) {
    .portfolio {
      padding: 0 10px;
    }

    .portfolio__work {
      margin: 90px 0 30px;
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(6, 1fr);
    }

    .portfolio__title {
      width: 380px;
      height: 50px;
      font-size: 17px;
      margin: 20px 0;
    }

    .portfolio-video__title {
      font-size: 13px;
      line-height: 15px;
    }
  }

  @media screen and (max-width: 325px) {
    .portfolio__video {
      min-height: 100px;
      min-width: 150px;
    }
  }