.information {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 70px 50px 50px;
  box-sizing: border-box;
  position: relative;
}

.information__title {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  align-items: center;
  color: #f1f1f1;
  margin: 0;
  padding-right: 40px;
  box-sizing: border-box;
  display: inline-block;
  align-self: flex-end;
  letter-spacing: 0.15em;
  line-height: 32px;
  display: flex;
  justify-content: flex-end;
  background-color: #4845e899;
  border-radius: 60px;
  height: 100px;
  width: 500px;
  position: absolute;
  left: -100px;
}

.information__grid {
  width: 100%;
  display: grid;
  row-gap: 80px;
  column-gap: 30px;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-template-areas:
  ". tyema"
  "freestyle tyema"
  "freestyle cinewhoop"
  "price cinewhoop"
  "price .";
}

.information__area {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.information__area_topic_about {
  grid-area: tyema;
}

.information__area_topic_cinewhoop {
  grid-area: cinewhoop;
}

.information__area_topic_freestyle {
  grid-area: freestyle;
}

.information__area_topic_price {
  grid-area: price;
}


.information__image {
  width: 100%;
  height: calc(100vh / 2);
  object-fit: cover;
  display: flex;
  align-items: center;
  filter: brightness(80%);
  border-radius: 40px;
}

.information__container {
	width: 85%;
	height: 130px;
  position: relative;
  z-index: -1;
}

.information__overlay {
  width: 100%;
  height: 100%;
  display: flex;
  border-radius: 40px;
	background-color:rgba(0, 0, 0, .45);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -50px;
  padding: 50px 0 0;
}

.information__text {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 16px;
  color: #c4c4c4;
  letter-spacing: 0.15em;
  line-height: 25px;
  text-align: center;
  width: 90%;
  margin: 0;
  padding: 0;
}

@media screen and (min-width: 1700px) {
    .information__title {
      width: 600px;
      height: 150px;
      font-size: 45px;
    }

    .information__grid {
      row-gap: 100px;
      column-gap: 50px;
      max-width: 2200px;
    }

    .information__container {
      width: 85%;
      height: 130px;
    }

    .information__text {
      font-size: 17px;
      line-height: 30px;
      width: 95%;
  }
}

@media screen and (max-width: 1280px) {
  .information__text {
    font-size: 15px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1025px) {
  .information__title {
    width: 400px;
    height: 70px;
    font-size: 20px;
  }

  .information__grid {
    row-gap: 50px;
    column-gap: 20px;
  }

  .information__container {
    width: 87%;
    height: 130px;
  }

  .information__text {
    font-size: 13px;
    line-height: 18px;
  }
}

@media screen and (max-width: 770px) {
  .information__container {
    height: 120px;
    width: 90%;
  }

  .information__overlay {
    border-radius: 25px;
  }

  .information {
    padding: 20px 20px 50px;
  }

  .information__text {
    font-size: 12px;
    line-height: 17px;
  }
}

@media screen and (max-width: 650px) {
  .information__text {
    font-size: 10.5px;
    line-height: 15px;
  }
}

@media screen and (max-width: 550px) {
  .information {
    padding: 10px 10px 50px;
  }

  .information__title {
    width: 300px;
    height: 50px;
    font-size: 17px;
    margin: 0;
  }

  .information__grid {
    margin-top: 90px;
    row-gap: 30px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-template-areas:
    "tyema"
    "freestyle"
    "cinewhoop"
    "price ";
  }

  .information__image {
    max-height: 200px;
    border-radius: 20px;
  }

  .information__overlay {
    top: -25px;
    padding: 22px 0 0;
  }

  .information__text {
    font-size: 14px;
    line-height: 18px;
    width: 95%;
  }
}

@media screen and (max-width: 400px) {
  .information__overlay {
    padding: 20px 0 0;
  }

  .information__text {
    font-size: 13px;
  }
}

@media screen and (max-width: 350px) {
  .information__overlay {
    padding: 25px 0 0;
  }

  .information__text {
    font-size: 12px;
    line-height: 15px;
  }
}