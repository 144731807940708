.button-fix {
  opacity: 0;
  position: fixed;
  top: 20px;
  right: 40px;
  border: 3px solid #4845e8;
  border-radius: 79px;
  width: 250px;
  height: 80px;
  background-color: #4845e899;
  color: #FFFFFF;
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
}

.button-fix:hover {
  background-color: rgb(82, 86, 190, 0.6);
  transform: scale(110%);
}

.button-fix_visible {
  opacity: 1;
  align-items: center;
  text-align: center;
  cursor: pointer;
  padding: 0;
  margin: 0;
  transition: background-color 0.6s, transform 0.5s;
  z-index: 3;
  letter-spacing: 0.15em;
  line-height: 32px;
}

@media screen and (min-width: 1441px) {
  .button-fix {
    top: 35px;
  }
}

@media screen and (max-width: 1439px) {
  .button-fix {
    right: 30px;
    width: 200px;
    height: 60px;
    font-size: 14px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1023px) {
  .button-fix {
    right: 20px;
    width: 180px;
    height: 50px;
    font-size: 12px;
  }
}

@media screen and (max-width: 767px) {
  .button-fix {
    width: 150px;
    height: 40px;
    font-size: 10px;
  }
}