.header {
  position: relative;
  height: 100vh;
  width: 100%;
}

.header__video {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
  object-fit: cover;
  object-position: center;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.header__content {
  position: absolute;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  padding: 0;
  margin: 0;
}

.header__title {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 75px;
  align-items: center;
  text-align: center;
  color: #f1f1f1;
  width: 100%;
  padding: 0;
  margin: 0;
  letter-spacing: 0.15em;
  line-height: 32px;
}

.header__text {
  font-family: "Manrope", "Arial", sans-serif;
  font-style: normal;
  font-weight: 200;
  font-size: 30px;
  align-items: center;
  text-align: center;
  color: #f1f1f1;
  width: 100%;
  padding: 0;
  margin: 80px 0 0;
  letter-spacing: 0.15em;
  line-height: 32px;
}

@keyframes go-arrow {
  from {
    top: calc(100vh - 100px);
  }

  50% {
    top: calc(100vh - 120px);
  }

  to {
    top: calc(100vh - 100px);
  }
}

.arrow {
  top: calc(100vh - 100px);
  background-repeat: no-repeat;
  height: 40px;
  width: 100px;
  position: relative;
  animation: go-arrow 1.5s 4 alternate-reverse ease-in 1s;
  background-image: url(../../images/arrow.svg);
  background-size: contain;
  cursor: pointer;
  margin: auto;
  opacity: 0;
}

.arrow_visible {
  opacity: 1;
}

@media screen and (max-width: 1439px) {
  .header__title {
    font-size: 50px;
    letter-spacing: 0.15em;
    line-height: 20px;
  }

  .header__text {
    font-size: 20px;
    letter-spacing: 0.15em;
    line-height: 20px;
  }

  .arrow {
    height: 30px;
    width: 60px;
    top: calc(100vh - 80px);
  }
}

@media screen and (max-width: 1023px) {
  .header__title {
    font-size: 50px;
    letter-spacing: 0.15em;
    line-height: 20px;
  }

  .header__text {
    font-size: 20px;
    letter-spacing: 0.15em;
    line-height: 20px;
    margin-top: 60px;
  }

  .arrow {
    top: calc(100vh - 100px);
  }
}

@media screen and (max-width: 767px) {
  .header__title {
    font-size: 35px;
    letter-spacing: 0.15em;
    line-height: 10px;
  }

  .header__text {
    font-size: 10px;
    letter-spacing: 0.15em;
    line-height: 20px;
    margin-top: 40px;
  }

  .arrow {
    top: calc(100vh - 140px);
  }
}

@media screen and (max-width: 424px) {
  .header__title {
    font-size: 25px;
    letter-spacing: 0.15em;
    line-height: 50px;
  }

  .header__text {
    font-size: 10px;
    letter-spacing: 0.15em;
    line-height: 20px;
    margin-top: 30px;
  }
}